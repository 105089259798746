import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => (
	<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 464.1" {...props}>
		<defs>
			<linearGradient
				id="linear-gradient"
				x1={-3096.3}
				x2={-3091.3}
				y1={1783.6}
				y2={1783.6}
				gradientTransform="matrix(0 -55.7 55.7 0 -99149.8 -171984.1)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#d85a25" />
				<stop offset={1} stopColor="#de8947" />
			</linearGradient>
			<linearGradient
				id="linear-gradient-2"
				x1={-3224}
				x2={-3219}
				y1={1673.9}
				y2={1673.9}
				gradientTransform="matrix(0 -6.7 6.7 0 -10940.6 -21239.8)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#d85a25" />
				<stop offset={1} stopColor="#de8947" />
			</linearGradient>
			<style>{".cls-1{fill:#032c64}.cls-6{fill:#fff}.cls-7{fill:#00877a}"}</style>
		</defs>
		<g id="Layer_1">
			<g id="Layer_1-2" data-name="Layer_1">
				<path
					d="M374.5 148.1H49.4c-19 0-34.4 15.5-34.4 34.6v19h359.5v-53.6Z"
					className="cls-1"
				/>
				<path
					d="M484 143c1.7 2.9.8 6.6-2.1 8.4L220.1 308.7c-2.9 1.7-6.6.8-8.4-2.1l-74.9-124.7c-1.7-2.9-.8-6.6 2.1-8.4L400.8 16.3c2.9-1.7 6.6-.8 8.4 2.1l74.9 124.7h-.1Z"
					style={{
						fill: "#48af93",
					}}
				/>
				<path
					d="m400.8 16.3-31.3 18.8c.4.9.9 1.8 1.4 2.7 11.4 18.9 36 25.1 54.9 13.7.8-.5 1.5-1 2.3-1.5l-19-31.6c-1.7-2.9-5.5-3.8-8.4-2.1h.1ZM463.5 114.2c-18.9 11.4-25.1 36-13.7 54.9l.8 1.1 31.3-18.8c2.9-1.7 3.8-5.5 2.1-8.4l-18.1-30.1c-.8.4-1.6.8-2.4 1.3ZM136.8 182l19 31.6c.7-.4 1.4-.7 2.1-1.1 18.9-11.4 25.1-36 13.7-54.9-.5-.9-1.1-1.7-1.7-2.5l-31 18.6c-2.9 1.7-3.8 5.5-2.1 8.4ZM195.6 275.2c-.7.4-1.3.9-2 1.3l18.1 30.1c1.7 2.9 5.5 3.8 8.4 2.1l31-18.6-.7-1.2c-11.4-18.9-35.9-25.1-54.9-13.7h.1ZM364.4 132.2c16.5 27.4 7.6 63.1-19.8 79.5-27.4 16.5-63.1 7.6-79.5-19.8-16.5-27.4-7.6-63.1 19.8-79.5 27.4-16.5 63-7.6 79.5 19.8Z"
					className="cls-7"
				/>
				<path
					d="m300.4 198.4 5.8-12.3c3.9 2.4 7.8 3.6 11.6 3.7 3.8 0 7.6-1 11.4-3.3 3.7-2.2 6.1-4.9 7.3-8.1 1.2-3.2.9-6.3-.9-9.2-1.4-2.3-3.3-3.8-5.8-4.4-2.5-.6-6-.4-10.6.6-.5 0-1.2.2-2.3.5-19.3 4.1-31.5 2-36.5-6.3-3.5-5.9-4.2-11.9-2.1-18.2s6.6-11.5 13.5-15.7c5.7-3.4 11.3-5.2 16.7-5.4 5.4-.2 10.3 1.3 14.6 4.4l-5.8 11.1c-3.2-2.2-6.5-3.4-10.1-3.4s-7.1.9-10.6 3c-3.3 2-5.4 4.5-6.4 7.6s-.6 6.1 1.1 9c2.4 4 8.8 4.8 19.2 2.3 2-.4 3.5-.8 4.6-1.1 8.5-2 15.1-2.4 19.7-1.1 4.6 1.3 8.3 4.3 11.2 9.2 3.8 6.2 4.6 12.6 2.5 19.1s-6.8 11.9-13.9 16.2c-6.1 3.7-12.1 5.7-17.7 5.9-5.7.3-11.2-1.1-16.6-4.2Z"
					className="cls-6"
				/>
				<path d="m284.23 126.544 10.8-6.49 46.302 77.06-10.8 6.489z" className="cls-6" />
				<path
					d="M375.1 433.8c0 9-7.3 16.3-16.3 16.3H31.4c-9 0-16.3-7.3-16.3-16.3V197.9c0-9 7.3-16.3 16.3-16.3h327.4c9 0 16.3 7.3 16.3 16.3v235.9Z"
					style={{
						fill: "url(#linear-gradient)",
					}}
				/>
				<path
					d="M375.3 284.4H272.2c-17.9 0-32.5 15.5-32.5 34.6v.7c0 19.1 14.5 34.6 32.5 34.6h103.1v-69.8Z"
					className="cls-1"
				/>
				<path
					d="M289 320c0 8-6.5 14.6-14.6 14.6s-14.6-6.5-14.6-14.6 6.5-14.6 14.6-14.6S289 311.9 289 320Z"
					style={{
						fill: "url(#linear-gradient-2)",
					}}
				/>
				<path
					d="M15.1 433.8V197.9c0-9 7.3-16.3 16.3-16.3h327.4L15.1 433.8Z"
					style={{
						fill: "#df9043",
					}}
				/>
			</g>
		</g>
	</SvgIcon>
);
export default Icon;
