import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 64 64" {...props}>
			<g clipPath="url(#clip0_2099_8000)">
				<path
					d="M0 32C0 49.6361 14.3639 64 32 64L34.1248 30.8738L32 0C14.3639 0 0 14.3639 0 32Z"
					fill="#FED92E"
				/>
				<path
					d="M32 0V64C49.6361 64 64 49.6361 64 32C64 14.3639 49.6361 0 32 0Z"
					fill="#FCBE00"
				/>
				<path
					d="M6.396 31.999C6.396 46.1079 17.8701 57.6033 32.0002 57.6033L34.1251 30.5117L32.0002 6.41605C17.8913 6.3948 6.396 17.8901 6.396 31.999Z"
					fill="#FCBE00"
				/>
				<path
					d="M32 6.39648V57.5837C46.1089 57.5837 57.6043 46.1096 57.6043 31.9795C57.6043 17.8493 46.1089 6.39648 32 6.39648Z"
					fill="#FE9002"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M14.0664 26.1366L26.3692 24.203L32.0001 13.1529V13.1113L32.0106 13.1321L32.0213 13.1113L32.0277 13.1657L37.6309 24.203L49.9337 26.1366L41.1369 34.9334L43.0917 47.2362L32.0107 41.5683L20.9296 47.2362L22.8845 34.9334L14.0664 26.1366Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2099_8000">
					<rect width="64" height="64" fill="white" />
				</clipPath>
			</defs>
		</SvgIcon>
	);
};

export default Icon;
