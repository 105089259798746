import { Plural, Trans } from "@lingui/macro";
import { Stack, Tooltip } from "@mui/material";
import { useAuthState } from "@saleor/sdk";
import moment from "moment";
import numbro from "numbro";

import Rewards from "@/icons/Rewards";
import { useGoalsmapperCoinBalanceQuery, useGoalsmapperCoinExpiringSoonQuery } from "@/saleor/api";

import { Spinner } from "../Spinner";

const GoalsMapperCoinWidget = () => {
	const { authenticated } = useAuthState();

	const { data: coinBalance, loading } = useGoalsmapperCoinBalanceQuery({
		skip: !authenticated || typeof window === "undefined",
		fetchPolicy: "cache-first",
	});

	const { data: expiringBalance, loading: expiringLoading } = useGoalsmapperCoinExpiringSoonQuery(
		{
			skip: !authenticated || typeof window === "undefined",
			fetchPolicy: "cache-first",
		}
	);

	if (loading || expiringLoading) {
		return <Spinner />;
	}

	const thirtyDays = moment().local().add(30, "days").format("ll");

	return (
		<div className="flex justify-center flex-col items-center">
			<Tooltip
				title={
					<Stack direction="column">
						<div className="text-sm">
							<Trans>
								Current Balance:{" "}
								{numbro(coinBalance?.goalsmapperCoinBalance || 0).format("0,0")}
							</Trans>
						</div>
						<div className="text-sm">
							<Trans>
								{numbro(expiringBalance?.goalsmapperCoinExpiringSoon || 0).format(
									"0,0"
								)}{" "}
								GM Coins expiring on {thirtyDays}
							</Trans>
						</div>
					</Stack>
				}
				arrow
			>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={1}
					mt={2}
					pr={2}
					mb={1}
					className="bg-gray-200 rounded-full w-full"
				>
					<Rewards style={{ width: 40, height: 40 }} />
					<div className="text-lg">
						<strong>
							{numbro(coinBalance?.goalsmapperCoinBalance || 0).format("0,0")}
						</strong>{" "}
						<Plural
							one="Coin"
							other="Coins"
							value={coinBalance?.goalsmapperCoinBalance || 0}
						/>
					</div>
				</Stack>
			</Tooltip>
		</div>
	);
};

export default GoalsMapperCoinWidget;
