import { Autorenew } from "@mui/icons-material";

require("@mui/icons-material/Autorenew");

export const Spinner = () => {
	return (
		<div className="flex items-center justify-center w-full h-full flex-grow gap-2">
			<Autorenew className="animate-spin w-5 h-5" />
			<div className="animate-pulse">Loading ...</div>
		</div>
	);
};
