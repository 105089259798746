import { useApolloClient } from "@apollo/client";
import { t } from "@lingui/macro";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
	Collapse,
	Divider,
	List,
	ListItemButton,
	ListItemText,
	ListSubheader,
} from "@mui/material";
import { useAuth, useAuthState } from "@saleor/sdk";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import GoalsMapperCoinWidget from "./GoalsMapperCoinWidget";
import WalletWidget from "./WalletWidget";

export type NavItem = {
	label?: string;
	slug: string;
	path?: string;
	onClick?: () => void;
	items?: NavItem[];
};

export type ProfileMenuListProps = {
	onItemClick?: (item: NavItem, event: React.MouseEvent<any, MouseEvent>) => void;
};

const ProfileMenuList: React.VFC<ProfileMenuListProps> = ({ onItemClick }) => {
	const { logout } = useAuth();
	const { user } = useAuthState();
	const router = useRouter();
	const client = useApolloClient();
	const locale = router.locale || router.defaultLocale!;

	const onLogout = async () => {
		await logout();
		await client.clearStore();
		router.push("/");
	};

	const onGetHelp = () => {
		if (window.zE) {
			window.zE.setLocale && window.zE.setLocale(locale);
			if (user && window.zE.identify) {
				window.zE.identify({
					name: `${user.firstName} ${user.lastName}`,
					email: user.email,
					organization: "GMR",
				});
			}
			window.zE("webWidget", "open");
		} else {
			console.error("window.ZE not loaded");
		}
	};

	const lists: NavItem[] = React.useMemo(() => {
		const partOne = [
			{
				label: t`Order History`,
				slug: "orders",
				path: "/account/orders",
				// onClick: goToOrderHistory,
			},
			{
				label: t`My Account`,
				slug: "account",
				items: [
					{
						label: t`Contacts`,
						slug: "contacts",
						path: "/account/contacts",
					},
					{
						label: t`Address Book`,
						slug: "addressBook",
						path: "/account/addresses",
					},
					{
						label: t`Change Password`,
						slug: "changePassword",
						path: "/account/change-password",
					},
					{
						label: t`E-Wallet`,
						slug: "wallet",
						path: "/account/wallet",
					},
				],
			},
		];

		const partTwo = [
			{
				slug: "divider-two",
			},
			{
				label: t`Administration`,
				slug: "admin",
				items: [
					{
						label: t`Users`,
						slug: "users",
						path: "/account/users",
					},
					{
						label: t`Vouchers`,
						slug: "vouchers",
						path: "/account/vouchers",
					},
					{
						label: t`Orders Placed`,
						slug: "orders-placed",
						path: "/admin/orders",
					},
					{
						label: t`Merchants`,
						slug: "merchants",
						path: "/account/merchants",
					},
					{
						label: t`Banners`,
						slug: "banners",
						path: "/account/banners",
					},
				],
			},
		];

		const partThree = [
			{
				slug: "divider-three",
			},
			{
				label: t`Help`,
				slug: "support",
				onClick: onGetHelp,
			},
			{
				label: t`Sign out`,
				slug: "sign-out",
				onClick: onLogout,
			},
		];
		if (user && user.isStaff) {
			return [...partOne, ...partTwo, ...partThree];
		} else {
			return [...partOne, ...partThree];
		}
	}, [user]);

	const [openKey, setOpenKey] = useState<string | null>();

	useEffect(() => {
		if (router.pathname.includes("/")) {
			const parentItem = lists.find((i) => i.items?.find((c) => c.path === router.pathname));
			if (parentItem) {
				setOpenKey(parentItem.slug);
			}
		}
	}, [router.pathname, setOpenKey]);

	const handleItemClick = (item: NavItem) => (event: React.MouseEvent<any, MouseEvent>) => {
		if (item.slug === openKey) {
			setOpenKey(null);
		} else {
			setOpenKey(item.slug);
		}
		if (item.onClick) {
			item.onClick();
			onItemClick && onItemClick(item, event);
		} else if (item.path) {
			onItemClick && onItemClick(item, event);
			router.push(item.path);
		}
	};

	return (
		<List
			sx={{
				width: "100%",
				minWidth: 220,
				maxWidth: 250,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
			subheader={
				<ListSubheader component="div" id="nested-list-subheader" className="pb-1">
					<GoalsMapperCoinWidget />
					<WalletWidget />
				</ListSubheader>
			}
		>
			{lists.map((item) => {
				if (item.slug.startsWith("divider-")) {
					return <Divider key={item.slug} />;
				}
				if (item.items) {
					return (
						<React.Fragment key={item.slug}>
							<ListItemButton onClick={handleItemClick(item)} sx={{ px: 3 }}>
								<ListItemText primary={item.label} />
								{openKey === item.slug ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
							<Collapse in={openKey === item.slug} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									{item.items.map((subItem) => (
										<ListItemButton
											sx={{ pl: 5 }}
											key={subItem.slug}
											onClick={handleItemClick(subItem)}
											selected={subItem.path === router.pathname}
										>
											<ListItemText primary={subItem.label} />
										</ListItemButton>
									))}
								</List>
							</Collapse>
						</React.Fragment>
					);
				}
				return (
					<ListItemButton
						key={item.slug}
						onClick={handleItemClick(item)}
						selected={item.path === router.pathname}
						sx={{ px: 3 }}
					>
						<ListItemText primary={item.label} />
					</ListItemButton>
				);
			})}
		</List>
	);
};

export default ProfileMenuList;
