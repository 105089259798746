import { Stack } from "@mui/material";
import { useAuthState } from "@saleor/sdk";
import Link from "next/link";
import numbro from "numbro";

import Wallet from "@/icons/Wallet";
import { useWalletQuery } from "@/saleor/api";

import useChannelContext from "../shared/contexts/ChannelContext";
import { Spinner } from "../Spinner";

const WalletWidget = () => {
	const { authenticated } = useAuthState();
	const { channel } = useChannelContext();

	const { data: walletBalance, loading } = useWalletQuery({
		variables: {
			channel,
		},
		skip: !authenticated || typeof window === "undefined",
		fetchPolicy: "cache-and-network",
	});

	if (loading) {
		return <Spinner />;
	}

	return (
		<Link href="/account/wallet">
			<div className="flex justify-center flex-col items-center cursor-pointer">
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={1}
					mt={2}
					pr={2}
					mb={1}
					className="bg-gray-200 rounded-full w-full"
				>
					<Wallet style={{ width: 40, height: 40 }} />
					<div className="text-lg">
						<strong>
							{numbro(walletBalance?.wallet?.balance?.amount || 0).format("$0,0.00")}
						</strong>{" "}
					</div>
				</Stack>
			</div>
		</Link>
	);
};

export default WalletWidget;
